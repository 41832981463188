import { Component } from 'react'

export default class Error404 extends Component {
  render() {
    return (
      <div style={{ height: '67vh', position: 'relative' }}>
        <p>Page not found </p>
        <p> 404 </p>
      </div>
    )
  }
}
