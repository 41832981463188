export const MemberList: IMember[] = [
  {
    name: 'Nishanth',
    position: 'Secretary',
    quote: 'Make others better as a result of your presence.',
    pictureUrl: 'https://avatars.githubusercontent.com/u/68420841',
    linkedInUrl: 'https://www.linkedin.com/in/nishanth-s-1b4a12191/',
    mailId: 'nishanth.ec19@bitsathy.ac.in',
  },
  {
    name: 'Kabilan',
    position: 'Competitive programming Lead',
    quote: "Let's climb the ladder of success together.",
    pictureUrl: 'https://avatars.githubusercontent.com/u/67632223',
    linkedInUrl: 'https://in.linkedin.com/in/kabilan-muthusamy',
    mailId: 'kabilan.ec19@bitsathy.ac.in',
  },
  {
    name: 'Dasaprakash',
    position: 'Events Manager',
    quote: 'I assuse that our events make students learn new things.',
    pictureUrl: 'https://avatars.githubusercontent.com/u/81621335',
    linkedInUrl: 'https://www.linkedin.com/in/dasaprakash-c-62908a194/',
    mailId: 'dasaprakash.ei19@bitsathy.ac.in',
  },
  {
    name: 'Mohanapraneswaran',
    position: 'Content Creater',
    quote: 'Hope I will  give big information within few lines.',
    pictureUrl: 'https://avatars.githubusercontent.com/u/53465394',
    linkedInUrl: 'https://www.linkedin.com/in/mohanapraneswaran-m-6083ab1a5/',
    mailId: 'mohanapraneswaran.it19@bitsathy.ac.in',
  },
  {
    name: 'Surya Prakash',
    position: 'Graphic Designer',
    quote: 'Make some changes, undo, redo.',
    pictureUrl: 'https://avatars.githubusercontent.com/u/83821435',
    linkedInUrl: 'https://www.linkedin.com/in/suryaprakash-m/',
    mailId: 'suryaprakash.ec20@bitsathy.ac.in',
  },
]
